<template>
  <RouterLink :to="`/goods/${goods.id}`" class="goods-item">
    <img :src="goods.img" />
    <p class="name ellipsis">
      {{ goods.productName }}
    </p>
    <div v-if="goods.tradeType == '1'" class="lable">保证金</div>
    <div v-else class="lable2">全款</div>

    <p class="price">&yen;{{ goods.activePrice }}/{{ goods.unitName }}</p>
  </RouterLink>
</template>

<script>
export default {
  name: 'GoodsMerchantItem',
  props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {}
  },
}
</script>

<style scoped lang="less">
.goods-item {
  display: block;
  width: 220px;
  min-height: 100%;
  padding: 10px 10px;
  // text-align: center;
  .hoverShadow();
  img {
    width: 220px;
    height: 220px;
  }
  p {
    padding-top: 10px;
  }
  .name {
    font-size: 14px;
    font-weight: 550;
  }
  .line {
    height: 1px;
    background: #dcdfe6;
  }
  .lable {
    background: #fff4e3;
    font-size: 11px;
    color: #dc9100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 4px;
    padding: 2px;
    width: 40px;
    height: 22px;
  }
  .lable2 {
    background: #ffeae9;
    font-size: 11px;
    color: #d12b23;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 4px;
    padding: 2px;
    width: 40px;
    height: 22px;
  }
  .desc {
    color: #999;
    font-size: 12px;
    height: 29px;
  }
  .price {
    color: @priceColor;
    font-size: 20px;
  }
}
</style>
