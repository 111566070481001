<template>
  <ul>
    <li v-for="item in goods" :key="item.id">
      <GoodsMerchantItme :goods="item" />
    </li>
  </ul>
</template>
<script>
import GoodsMerchantItme from '@/views/member/shop/components/GoodsMerchantItme'
// import GoodsItem from '@/views/category/components/GoodsItem'
export default {
  name: 'GoodsList',
  components: { GoodsMerchantItme },
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped lang="less">
ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5px;
  li {
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
