<template>
  <div class="member-home">
    <ShopPagePanel title="店铺信息">
      <div class="container" style="margin-top: 25px">
        <!-- 公司名 logo -->
        <Row style="margin-bottom: 20px">
          <Col flex="65px">
            <div
              style="
                height: 64px;
                width: 64px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #cfcdcd;
              "
            >
              <img :src="require('/static/icons/logo.png')" />
            </div>
          </Col>
          <Col flex="auto">
            <div
              style="
                height: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <Row>
                <span
                  style="
                    color: #333333;
                    font-size: 18px;
                    font-weight: 550;
                    margin-left: 10px;
                  "
                >
                  {{ merchantStore.businessName }}
                </span>
              </Row>
            </div>
            <div
              style="
                height: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <Row>
                <span
                  style="color: #333333; font-size: 14px; margin-left: 10px"
                >
                  会面码：
                  <span style="color: #d12b23">
                    {{ merchantStore.meetCode }}
                  </span>
                  <Icon type="ios-create-outline" style="margin-left: 8px" />
                  <a style="color: #409eff; font-size: 11px" @click="edit">
                    编辑
                  </a>
                </span>
              </Row>
            </div>
          </Col>
        </Row>
        <!-- 公司信息 -->
        <div v-for="item in companyData" :key="item.contactPhone" class="item">
          <Row style="border: 1px solid #eee; height: 42px">
            <Col span="4">
              <span style="margin-left: 20px">{{ item.key }}</span>
            </Col>
            <Col span="18">
              <span style="margin-left: 20px">{{ item.value }}</span>
            </Col>
          </Row>
        </div>
        <!-- 供应商品-->
        <div class="title">
          <span class="icon"></span>
          <span class="text">供应商品</span>
        </div>
        <div class="goods-list">
          <!-- 商品列表 -->
          <GoodsMerchantList v-if="goods" :goods="goods" />
          <!-- 无限列表加载组件 -->
          <XtxInfiniteLoading
          />
        </div>

        <!-- 采购商品-->
        <!-- <div class="title">
          <span class="icon"></span>
          <span class="text">采购商品</span>
        </div> -->
      </div>
    </ShopPagePanel>
    <Modal v-model="modal" title="修改会面码" @on-ok="ok" @on-cancel="cancel">
      <Input v-model="value" placeholder="修改..." style="width: 300px" />
    </Modal>
  </div>
</template>
<script>
import ShopPagePanel from '@/views/member/shop/components/ShopPagePanel'
import GoodsMerchantList from '@/views/member/shop/components/GoodsMerchantList'
// import { Input } from 'view-ui-plus'
export default {
  name: 'ShopPage',
  components: {
    ShopPagePanel,
    GoodsMerchantList,
  },
  data () {
    return {
      goods: {},
      merchantStore: {},
      productList: {},
      companyData: [],
      value: '',
      modal: false,
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
			this.postData()
		},
    postData () {
      this.$store
        .dispatch('StoreDetailByToken')
        .then((response) => {
          this.companyData = []
          this.merchantStore = response.result.merchantStore
          this.companyData.push({
            key: '电话',
            value: this.merchantStore.contactPhone,
          })
          this.companyData.push({
            key: '邮箱',
            value: this.merchantStore.email,
          })
          this.companyData.push({
            key: '地址',
            value: this.merchantStore.address,
          })
          this.goods = response.result.productList
        })
        .catch(() => {})
    },
    ok () {
      const meetCode = this.value
      const storeId = this.merchantStore.id
      this.$store
        .dispatch('MerchantSetMeetCode', { storeId, meetCode })
        .then((response) => {
          if (response.returnCode === '1') {
						this.postData()
          }
        })
        .catch(() => {})
    },
    cancel () {
      this.value = ''
    },
    edit () {
      this.modal = true
    },
  },
  setup () {},
}
</script>
<style scoped lang="less">
.member-home {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
.item {
  flex: 1;
  // margin-left: 46px;
  .desc {
    color: #333333;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .num {
    color: #333333;
    font-size: 24px;
  }
  .order {
    color: #333333;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
}

.title {
  height: 20px;
  line-height: 1;
  margin-bottom: 10px;
  padding: 25px 5px;
  .icon {
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: #d12b23;
    margin-right: 10px;
    vertical-align: middle;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
  }
}
/deep/ .ivu-col-span-4 {
  background: #f8f8fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .ivu-col-span-18 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}
</style>
